.partners {
    margin-bottom: 100px;

    .partners-title {
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 400;
        line-height: 17.07px;
        text-align: center;
    }

    .partners-container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 30px;

        .partners-logo {
            width: 100px;
            margin: 25px 25px;
        }
    }

    .mobile-view {
        display: none;
    }

}

@media only screen and (max-width: 975px) {
    .partners-container {
        flex-direction: column;
        gap: 30px;
    }

    .web-view {
        display: none !important;
    }

    .mobile-view {
        display: flex !important;
    }
}
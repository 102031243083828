.marquee-container {
    margin: 60px 0px;

    .marquee {
        &-content {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            &-text {
                font-family: Montserrat;
                font-size: 56px !important;
                font-style: italic;
                font-weight: 600;
                line-height: 72px;
                text-align: left;
                color: #000000;
            }

            &-symbol {
                margin: 0px 40px;
                font-size: 30px;
                color: #2AC18E;
            }
        }
    }
}

@media only screen and (max-width: 975px) {
    .marquee-container {
        margin: 20px 0px;

        .marquee {
            &-content {
                &-text {
                    font-family: Montserrat;
                    font-size: 32px !important;
                    font-style: italic;
                    font-weight: 600;
                    line-height: 72px;
                    text-align: left;
                    color: #000000;
                }

                &-symbol {
                    margin: 0px 20px;
                    font-size: 15px;
                    color: #2AC18E;
                }
            }
        }
    }
}
.solution {
    display: flex;
    flex-direction: row;
    margin: 200px 100px;
    justify-content: space-between;
    align-items: center;
    gap: 100px;

    &-left {
        display: flex;
        flex-direction: column;
        flex: 5;

        &-title {
            font-family: Montserrat;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.04em;
            text-align: left;
            color: #2AC18E;
            margin-bottom: 30px;
        }

        &-header {
            font-family: Montserrat;
            font-size: 32px;
            font-weight: 700;
            line-height: 40px;
            text-align: left;
            color: #121417;

        }

        &-body {
            font-family: Montserrat;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            text-align: left;
            color: #5A6475;
            margin-bottom: 60px;
        }

        .solution-crumbs {
            display: flex;
            flex-direction: column;
            gap: 30px;

            &-phone {
                display: none;
            }

            &-layout {
                display: flex;
                flex-direction: column;
                border-radius: 24px;
                padding: 20px 25px;
                cursor: pointer;
            }

            &-green {
                background-color: #EAF9F4;

                .solution-crumbs-item-title {

                    color: #1B8E67;

                }

                .solution-crumbs-item-content {

                    color: #1B8E67;

                }

            }

            &-item {
                background: #F2F2F2;

                &-title {
                    font-family: Montserrat;
                    font-size: 18px;
                    font-weight: 700;
                    line-height: 24px;
                    text-align: left;
                    color: #000000;
                    margin-bottom: 15px;
                }

                &-content {
                    font-family: Montserrat;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                    text-align: left;
                    color: #000000;
                }
            }
        }
    }

    &-right {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        flex: 3;

        &-phone {
            max-height: 800px;
        }
    }
}


@media only screen and (max-width: 975px) {
    .solution {
        display: flex;
        flex-direction: column;
        margin: 0px 50px 100px 50px;
        justify-content: center;
        align-items: center;
        gap: 0px;

        &-left {
            flex-direction: column;
            width: 100%;

            &-title {
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: 0.04em;
                margin-bottom: 10px;
            }

            &-header {
                font-size: 24px;
                font-weight: 700;
                line-height: 32px;

            }

            &-body {
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;

            }

            .solution-crumbs {
                gap: 40px;

                &-phone {
                    display: flex;
                    justify-content: center;
                    background-color: #F2F2F2;
                    border-radius: 24px;
                    width: 100%;
                    padding-top: 70px;
                    overflow: hidden;

                    &-image {
                        width: 60%;
                        margin-bottom: -60%;
                    }
                }

                &-layout {
                    display: flex;
                    flex-direction: column;
                    border-radius: 24px;
                    padding: 20px 0px;
                    cursor: pointer;
                }

                &-green {
                    background-color: inherit;

                    .solution-crumbs-item-title {
                        color: #000000;
                    }

                    .solution-crumbs-item-content {
                        color: #000000;
                    }

                }

                &-item {
                    background-color: inherit;

                    &-title {
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 24px;
                        text-align: left;
                        margin-bottom: 5px;
                    }

                    &-content {
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 20px;
                        text-align: left;
                    }
                }
            }
        }

        &-right {
            display: none;
        }
    }

}
.waitlist-container {
    margin: 30px 50px;

    .waitlist {
        display: flex;
        background: linear-gradient(185.76deg, #FFFFFF -23.71%, rgba(42, 193, 143, 0.514) 158.03%);
        width: Fill (1, 376px)px;
        padding: 64px 60px 64px 60px;
        border-radius: 48px;
        gap: 10%;

        &-left {
            display: flex;
            flex-direction: column;
            flex: 1;

            &-title {
                font-family: Montserrat;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0.04em;
                text-align: left;
                color: #2AC18E;
                margin-bottom: 30px;
            }

            &-header {
                font-family: Montserrat;
                font-size: 32px;
                font-weight: 700;
                line-height: 40px;
                text-align: left;
                color: #121417;
                margin-bottom: 30px;
            }

            &-body {
                font-family: Montserrat;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                text-align: left;
                color: #5A6475;
                margin-bottom: 30px;
            }

            &-perks {
                display: flex;
                flex-direction: column;

                &-container {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    margin-bottom: 20px;
                }

                &-icon {
                    width: 19.51px;
                    height: 14.25px;
                }

                &-content {
                    font-family: Montserrat;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                    text-align: left;
                    color: #000000;
                    margin-left: 10px;
                }
            }

        }

        &-right {
            display: flex;
            flex-direction: column;
            flex: 1;
            justify-content: center;
            align-items: flex-start;

            &-container {
                display: flex;
                flex-direction: column;
                margin-bottom: 30px;
                width: 95%;

                &-label {
                    font-family: Montserrat;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                    text-align: left;
                    color: #000000;
                    margin-bottom: 10px;
                }

                &-input {
                    width: 100%;
                    height: Hug (48px)px;
                    padding: 12px;
                    gap: 8px;
                    border-radius: 12px;
                    border: none;
                    box-sizing: border-box;

                    font-family: Montserrat;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                    text-align: left;
                    color: #5A6475;
                }

                &-checkbox {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    margin-bottom: 10px;

                    &-input {
                        width: auto;
                        margin-right: 10px;
                        height: 20px;
                    }

                    &-title {
                        font-family: Montserrat;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 24px;
                        text-align: left;
                    }
                }

                select {
                    -webkit-appearance: none;
                    appearance: none;
                }

                select {
                    background-image: url("./../../assets/select-arrow.png");
                    background-size: 24px;
                    background-repeat: no-repeat;
                    background-position: calc(100% - 8px) center;
                }
            }

            &-join {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: Hug (166px)px;
                height: Hug (52px)px;
                padding: 16px;
                gap: 8px;
                border-radius: 8px;

                font-family: Montserrat;
                border: none;

                background-color: #2AC18E;
                font-family: Montserrat;
                font-size: 16px;
                font-weight: 600;
                line-height: 20px;
                text-align: center;
                color: #ffffff;

                cursor: pointer !important;
            }

            &-join-disabled {
                background-color: #DDDDDD !important;
            }

        }
    }
}


@media only screen and (max-width: 975px) {

    .waitlist-container {
        margin: 30px 20px;

        .waitlist {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: Fill (1, 376px)px;
            padding: 30px 32px;
            border-radius: 48px;
            gap: 30px;


            &-left {
                display: flex;
                flex-direction: column;
                flex: 1;

                &-title {
                    font-family: Montserrat;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    letter-spacing: 0.04em;
                }

                &-header {
                    font-family: Montserrat;
                    font-size: 25px;
                    font-weight: 700;
                    line-height: 32px;
                    text-align: left;
                    color: #121417;
                    margin-bottom: 30px;
                    width: 100%;
                }

                &-body {
                    font-family: Montserrat;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                    text-align: left;
                    color: #5A6475;
                    width: 75%;
                    margin-bottom: 30px;
                    width: 100%;
                }

                &-perks {
                    display: flex;
                    flex-direction: column;
                    width: 100%;

                    &-container {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        margin-bottom: 20px;
                    }

                    &-icon {
                        width: 19.51px;
                        height: 14.25px;
                    }

                    &-content {
                        font-family: Montserrat;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 24px;
                        text-align: left;
                        color: #000000;
                        margin-left: 10px;
                    }
                }

            }

            &-right {
                align-items: center;
                width: 100%;

                &-container {
                    margin-bottom: 30px;
                    width: 100%;

                    &-label {
                        font-family: Montserrat;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 24px;
                        text-align: left;
                        color: #000000;
                        margin-bottom: 10px;
                    }

                    &-input {
                        width: 100%;
                        height: Hug (48px)px;
                        padding: 12px;
                        gap: 8px;
                        border-radius: 12px;
                        border: none;
                        box-sizing: border-box;

                        font-family: Montserrat;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 24px;
                        text-align: left;
                        color: #5A6475;

                    }

                    select {
                        -webkit-appearance: none;
                        appearance: none;
                    }

                    select {
                        background-image: url("./../../assets/select-arrow.png");
                        background-size: 24px;
                        background-repeat: no-repeat;
                        background-position: calc(100% - 8px) center;
                    }
                }

                &-join {
                    justify-content: center;
                    align-items: center;
                    font-family: Montserrat;
                    border: none;

                    background-color: #2AC18E;
                    font-family: Montserrat;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 20px;
                    text-align: center;
                    color: #ffffff;

                    width: 100%;
                }

            }
        }
    }

}
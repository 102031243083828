.problem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &-title {
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.04em;
        text-align: center;
        color: #2AC18E;
        margin-bottom: 50px;
    }

    &-content {
        max-width: 700px;

        &-header {
            font-family: Montserrat;
            font-size: 32px;
            font-weight: 700;
            line-height: 40px;
            text-align: center;
            color: #121417;
        }

        &-body {
            margin-top: 30px;
            font-family: Montserrat;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            text-align: center;
            color: #5A6475;
        }
    }

    &-crumbs {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        margin: 70px 0px;
        width: 80%;

        &-item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            max-width: 352px;

            &-icon {
                max-width: 56px;
                max-height: 56px;
                margin-bottom: 15px;
            }

            &-header {
                font-family: Montserrat;
                font-size: 18px;
                font-weight: 600;
                line-height: 24px;
                text-align: center;
                color: #121417;

            }

            &-description {
                margin-top: 20px;
                font-family: Montserrat;
                font-size: 14px;
                font-weight: 500;
                line-height: 24px;
                text-align: center;
                color: #5A6475;

            }
        }
    }
}

@media only screen and (max-width: 975px) {
    .problem {
        &-title {
            margin-bottom: 10px;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0.04em;

        }

        &-content {
            max-width: 350px;

            &-header {
                font-size: 24px;
                font-weight: 700;
                line-height: 32px;
                text-align: center;

            }

            &-body {
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                text-align: center;
                margin-top: 10px;
            }
        }

        &-crumbs {
            flex-direction: column;
            justify-content: center;
            align-items: center;

            &-item {
                margin-bottom: 50px;

                &-header {
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 24px;
                    text-align: center;
                }

                &-description {
                    margin-top: 10px;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 20px;
                    text-align: center;
                }
            }
        }
    }
}
.landing-header-container {
    position: relative;
    background-image: url('./../../assets/header-background.jpg');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    max-height: 1000px;
    margin: 10px 50px 50px;
    padding: 50px 70px 0px 70px;
    border-radius: 40px;
    overflow: hidden;


    .landing-header-top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .logo {
            max-width: 122px;
            max-height: 24px;
        }
    }

    .landing-header-body {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 100px;
        height: 100%;

        &-left {
            margin-top: 40px;
            display: flex;
            flex-direction: column;
            justify-content: left;
            align-items: flex-start;
            flex-wrap: flex;
            flex: 1;

            .landing-header-title-container {
                display: flex;
                flex-direction: column;
                flex-direction: column;
                justify-content: left;
                align-items: flex-start;
            }

            .landing-header-title {
                font-family: Montserrat;
                font-size: 60px;
                font-weight: 900;
                line-height: 80px;
                text-align: left;
                color: #ffffff;
                width: 90%;
                word-break: normal;
                flex-wrap: flex;

                .marquee {
                    color: #2AC18E !important;
                    font-style: italic;
                    font-family: Montserrat;
                    font-size: 60px;
                    font-weight: 900;
                    line-height: 80px;
                    text-align: left;
                }
            }

            .landing-header-description {
                font-family: Montserrat;
                font-size: 22px;
                font-weight: 400;
                line-height: 40px;
                text-align: left;
                color: #ffffff;
                width: 100%;
                word-break: normal;
                flex-wrap: flex;
                width: 90%;
                margin-bottom: 30px;
            }
        }

        &-right {
            display: flex;
            justify-content: flex-end;
            flex-direction: column;
            align-items: flex-end;
            flex: 1;

            .image-container {
                display: flex;
                flex: 1;
                padding-top: 50px;

                background-repeat: no-repeat;
                animation: slideInFromBottom 1.5s ease-in;
                max-width: 85%;
                height: 100%;

                img {
                    margin-bottom: -50%;
                    width: 100%;
                    display: block;
                    height: auto;
                }
            }



            @keyframes slideInFromBottom {
                0% {
                    transform: translateY(100%);
                }

                100% {
                    transform: translateY(0);
                }
            }
        }
    }


    .landing-header-join {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: Hug (194px)px;
        height: Hug (36px)px;
        padding: 8px 16px 8px 16px;
        gap: 8px;
        border-radius: 8px;
        font-family: Montserrat;

        background-color: #2AC18E;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        text-align: center;
        color: #ffffff;
        border: none;

        cursor: pointer;

        img {
            height: 30px;
        }
    }

}

@media only screen and (max-width: 975px) {


    .landing-header-container {
        .landing-header-top {

            .logo {
                margin-bottom: 0px;
            }

            .landing-header-join {
                display: none;
            }
        }

        padding: 30px 28px 0px 28px;
        margin: 5px 10px 50px;
    }



    .landing-header-body {
        flex-direction: column !important;
        margin-top: 20px !important;

        &-left {
            display: flex;
            width: 100% !important;
            margin-bottom: 30px;


            .landing-header-title {

                font-family: Montserrat;
                font-size: 28px !important;
                font-weight: 700 !important;
                line-height: 42px !important;
                text-align: left !important;
                color: #ffffff;
                width: 100% !important;

                .marquee {
                    color: #2AC18E !important;
                    font-style: italic;
                    font-family: Montserrat;

                    font-size: 28px !important;
                    line-height: 42px !important;
                    text-align: left;
                }
            }

            .landing-header-description {
                font-family: Montserrat;
                font-size: 16px !important;
                font-weight: 400 !important;
                line-height: 24px !important;
                text-align: left;
                color: #ffffff;
                word-break: normal;
                flex-wrap: flex;
                width: 100% !important;
            }

            .landing-header-join {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 12px;

                img {
                    height: 20px;
                    width: 20px;
                }
            }
        }

        &-right {
            align-items: center !important;

            img {
                margin-bottom: -60% !important;
            }
        }
    }
}

@media only screen and (min-width: 976px) and (max-width: 1300px) {
    .landing-header-container {
        .landing-header-body {
            margin-top: 30px;

            &-left {
                .landing-header-title {
                    font-size: 35px;
                    line-height: 40px;
                    text-align: left;
                    color: #ffffff;
                    width: 90%;

                    .marquee {
                        font-size: 35px !important;
                        line-height: 42px !important;
                    }
                }

                .landing-header-description {
                    margin-top: 30px;
                    font-size: 20px !important;
                    line-height: 30px;
                    text-align: left;
                    color: #ffffff;
                    width: 100%;
                    word-break: normal;
                    flex-wrap: flex;
                    width: 90%;
                }
            }
        }

    }
}
.benefit {
    background: linear-gradient(0deg, #070a21d7, #070a21d5),
        radial-gradient(101.08% 66.24% at 50% 50%, rgba(0, 0, 0, 0.505) 0%, rgb(42, 193, 143) 100%);

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    padding: 200px 100px;

    &-left {
        display: flex;
        flex-direction: column;
        max-width: 30%;

        &-sticky {
            position: sticky;
            top: 200px;
        }

        &-title {
            font-family: Montserrat;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.04em;
            text-align: left;
            color: #2AC18E;
            margin-bottom: 30px;
        }

        &-header {
            font-family: Montserrat;
            font-size: 64px;
            font-weight: 700;
            line-height: 72px;
            text-align: left;
            color: #ffffff;
            margin-bottom: 30px;
        }

        &-body {
            font-family: Montserrat;
            font-size: 18px;
            font-weight: 400;
            line-height: 28px;
            text-align: left;
            color: #D8D8D8;
        }
    }

    &-right {
        display: flex;
        flex-direction: column;
        max-width: 50%;
        justify-content: center;
        align-items: center;
        gap: 400px;
        margin: 40px 0px;

        &-item {
            max-width: 50%;

            &-icon {
                max-width: 96px;
                max-height: 96px;
                margin-bottom: 25px;
            }

            &-title {
                font-family: Montserrat;
                font-size: 24px;
                font-weight: 700;
                line-height: 33.6px;
                text-align: center;
                color: #ffffff;
                margin-bottom: 20px;
            }

            &-content {
                font-family: Montserrat;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                text-align: center;
                color: #D8D8D8;
            }
        }
    }

}

@media only screen and (max-width: 975px) {
    .benefit {
        flex-direction: column;
        justify-content: space-between;

        padding: 70px 60px;

        &-left {
            display: flex;
            flex-direction: column;
            max-width: 100%;

            &-title {

                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: 0.04em;
                text-align: left;
            }

            &-header {
                font-size: 24px;
                font-weight: 700;
                line-height: 32px;
                text-align: left;

            }

            &-body {
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                text-align: left;

            }
        }

        &-right {
            max-width: 100%;
            gap: 80px;
            margin: 40px 0px;

            &-item {
                max-width: 100%;

                &-icon {
                    width: 72px;
                    height: 72px;
                    margin-bottom: 10px;
                }

                &-title {
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 24px;
                    text-align: center;
                    margin-bottom: 10px;
                }

                &-content {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    text-align: center;
                }
            }
        }

    }
}
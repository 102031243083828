.footer {
    display: flex;
    flex-direction: column;
    margin: 80px 90px;
    justify-content: center;
    align-items: center;

    &-top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        border-bottom: 1px solid #00000033;

        padding-bottom: 40px;

        &-left {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
            margin-bottom: 20px;

            font-family: Montserrat;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            text-align: left;
            color: #000000;

            img {
                margin-bottom: 10px;
            }
        }

        &-right {
            display: flex;
            flex-direction: column;
            max-width: 380px;

            &-header {
                font-family: Montserrat;
                font-size: 14px;
                font-weight: 400;
                line-height: 21px;
                text-align: left;
                color: #000000;
                margin-bottom: 20px;
            }

            &-content {
                display: flex;
                justify-content: flex-start;
                align-items: center;

                .email-address-logo {
                    width: 24px;
                    height: 24px;
                }

                .email-address {
                    font-family: Montserrat;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 24px;
                    text-align: left;
                    color: #000000;
                    margin-left: 10px;
                }
            }

            &-mobile {
                display: none;
            }
        }
    }

    &-bottom {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        margin-top: 30px;

        &-left {
            display: flex;
            flex-direction: row;

            .rights {
                font-family: Montserrat;
                font-size: 14px;
                font-weight: 400;
                line-height: 21px;
                text-align: left;
                color: #000000;
            }

            .terms {
                &-item {
                    font-family: Montserrat;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 21px;
                    text-align: left;
                    color: #000000;
                    text-decoration: underline;
                    margin-left: 30px;
                }
            }
        }

        &-right {
            .social-media-icon {
                width: 24px;
                height: 24px;
                margin-left: 15px;
            }
        }
    }
}


@media only screen and (max-width: 975px) {
    .footer {
        margin: 80px 40px;

        &-top {
            display: flex;
            flex-direction: column;
            gap: 30px;

            &-left {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
            }

            &-right {
                display: flex;
                flex-direction: column;
                max-width: 380px;

                &-header {
                    font-family: Montserrat;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 21px;
                    text-align: left;
                    color: #000000;
                    margin-bottom: 20px;
                }

                &-content {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;

                    .email-address-logo {
                        width: 24px;
                        height: 24px;
                    }

                    .email-address {
                        font-family: Montserrat;
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 24px;
                        text-align: left;
                        color: #000000;
                        margin-left: 10px;
                    }
                }

                &-mobile {
                    display: flex;
                    justify-content: flex-start;
                    gap: 15px;
                    margin-top: 20px;

                    .social-media-icon {
                        width: 24px;
                        height: 24px;
                    }
                }
            }
        }

        &-bottom {
            display: flex;
            flex-direction: column;

            &-left {
                display: flex;
                flex-direction: column-reverse;
                margin-bottom: 20px;
                align-items: flex-start;

                .terms {
                    display: flex;
                    gap: 25px;
                    margin-bottom: 20px;

                    &-item {
                        margin-left: 0px;
                    }
                }
            }

            &-right {
                display: none;
            }
        }
    }
}